import React from "react";
import "./technology.scss";
import Tech1 from "../../../assets/images/tech1.svg";
import Tech2 from "../../../assets/images/tech2.svg";
import Tech3 from "../../../assets/images/tech3.svg";
import Tech4 from "../../../assets/images/tech4.svg";
import { Fade, Zoom } from "react-reveal";
export default function Technology() {
  const Technology_Served = [
    {
      image: Tech1,
      heading: "Web",
      description:
        "Craft responsive, user-centric websites with our expert web development services.",
    },
    {
      image: Tech2,
      heading: "IOS",
      description:
        "Elevate user experiences with our seamless iOS app development solutions.",
    },
    {
      image: Tech3,
      heading: "Android",
      description:
        "Optimize engagement with our dynamic Android app development services.",
    },
    {
      image: Tech4,
      heading: "IoT",
      description:
        "Drive innovation with our IoT solutions for connected, intelligent ecosystems.",
    },
  ];
  return (
    <section className="technology_serve_sec">
      <div className="container">
        <div className="technology_heading">
          <Fade bottom duration={600} delay={400}>
            <h4 className="heading" title="Technology We Serve">
              The <span>Technology</span> Solutions <span>We Provide</span>
            </h4>
          </Fade>
          <Fade bottom duration={700} delay={500}>
            <p>
              From artificial intelligence to the Internet of Things, we offer
              innovative solutions designed to meet your specific needs.
              Discover how our expertise in diverse technologies can drive your
              success and transform your vision into reality.
            </p>
          </Fade>
        </div>
        <div className="grid-card-main">
          {Technology_Served &&
            Technology_Served?.map((el, i) => {
              return (
                <div className="sub-card" key={i} title={el?.heading}>
                  <div className="image-flex">
                    <Zoom>
                      <img src={el?.image} alt="tech" />
                    </Zoom>
                  </div>
                  <Fade bottom duration={600} delay={400}>
                    <h3>{el?.heading}</h3>
                  </Fade>
                  <Fade bottom duration={700} delay={500}>
                    <p>{el?.description}</p>
                  </Fade>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
}
