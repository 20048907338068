import React from "react";
import "./sectiontext.scss";
import Laptop from "../../../assets/images/Rectangle Blog-Details.svg";
import { Fade, Zoom } from "react-reveal";
export default function BDsection() {
  return (
    <section className="blog_details_text_sec">
      <div className="container">
        <Fade bottom duration={600} delay={400}>
          <h3>Characteristics of impactful experiences include:</h3>
        </Fade>
        <Fade bottom duration={700} delay={500}>
          <ul>
            <li>
              Seamless Interaction: Users can navigate effortlessly through the
              interface, completing tasks without unnecessary complications.
            </li>
            <li>
              Delightful Moments: Incorporating elements that surprise and
              delight users, creating memorable and positive associations.
            </li>
            <li>
              Personalization: Tailoring the experience to individual user
              preferences, making interactions more relevant and meaningful.
            </li>
            <li>
              Consistency: Providing a consistent experience across different
              devices and touchpoints, reinforcing brand identity and usability.
            </li>
            <li>
              Feedback and Responsiveness: Offering clear feedback for user
              actions and ensuring the system responds promptly, enhancing the
              feeling of control and engagement.
            </li>
          </ul>
        </Fade>
        <Zoom>
          <img src={Laptop} alt="laptop" />
        </Zoom>
        <Fade bottom duration={600} delay={400}>
          <h3>1. Principles of Good Design</h3>
        </Fade>
        <Fade bottom duration={700} delay={500}>
          <h4>
            Good design is at the heart of effective UI/UX. Understanding and
            implementing key design principles is crucial for creating
            interfaces that are not only aesthetically pleasing but also
            functional and user-friendly. Some fundamental principles include:
          </h4>
        </Fade>
        <Fade bottom duration={800} delay={600}>
          <ul>
            <li>
              Clarity: Ensuring that the design communicates its purpose clearly
              to users.
            </li>
            <li>
              Consistency: Maintaining a uniform design language throughout the
              interface.
            </li>
            <li>
              Hierarchy: Organizing elements to guide users' attention and
              prioritize information.
            </li>
            <li>
              Simplicity: Striving for simplicity without sacrificing
              functionality.
            </li>
            <li>
              Feedback: Providing users with clear feedback on their
              interactions.
            </li>
          </ul>
        </Fade>
        <Fade bottom duration={600} delay={400}>
          <h3>2. Historical Evolution of UI/UX</h3>
        </Fade>
        <Fade bottom duration={700} delay={500}>
          <h4>
            The field of UI/UX design has evolved significantly over time.
            Initially, the focus was primarily on functionality, with aesthetics
            taking a backseat. As technology progressed, the importance of user
            experience gained prominence. Key milestones include:
          </h4>
        </Fade>
        <Fade bottom duration={800} delay={600}>
          <ul>
            <li>
              Pre-Computer Era: Understanding how physical interfaces influenced
              early user interactions.
            </li>
            <li>
              Graphical User Interfaces (GUIs): The shift from command-line
              interfaces to graphical interfaces.
            </li>
            <li>
              Web Revolution: The emergence of the internet led to a new set of
              design challenges and opportunities.
            </li>
            <li>
              Mobile Era: The advent of smartphones brought about a mobile ui
              design approach.
            </li>
          </ul>
        </Fade>
        <Fade bottom duration={600} delay={400}>
          <h3>3. Key Components and Elements</h3>
        </Fade>
        <Fade bottom duration={700} delay={500}>
          <h4>
            UI/UX design involves a set of foundational components and elements
            that collectively contribute to a cohesive user experience: Layout:
            Arrangement of visual elements on a page to create a logical flow.
          </h4>
        </Fade>
        <Fade bottom duration={800} delay={600}>
          <ul>
            <li>
              Color Scheme: Strategic use of colors to convey information and
              evoke emotions.
            </li>
            <li>
              Typography: Selecting fonts and text styles to enhance readability
              and convey the brand personality.
            </li>
            <li>
              Images and Icons: Visual elements that aid in communication and
              navigation.
            </li>
            <li>
              Navigation: The structure and organization of the interface to
              facilitate user movement.
            </li>
          </ul>
        </Fade>
        <Fade bottom duration={900} delay={700}>
          <p>
            Understanding the historical context and mastering these
            foundational elements is essential for designers to build upon and
            create meaningful, user-centric experiences. As technology continues
            to advance, these principles provide a solid framework for adapting
            to new challenges and opportunities in the dynamic field of UI/UX
            design.
          </p>
        </Fade>
      </div>
    </section>
  );
}
