import React from "react";
import "./bloghead.scss";
import IconHome from "../../../assets/images/9104258_home_house_homepage_icon 1.svg";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";
export default function Heading() {
  return (
    <section className="blog_head">
      <div className="blur"></div>
      <Fade bottom duration={600} delay={400}>
        <h1 title="Insights & Innovations: Our Digital Blog">Insights & Innovations: Our Digital Blog</h1>
      </Fade>
      <div className="flex-main">
        <Fade bottom duration={700} delay={500}>
          
            <img src={IconHome} alt="HomeIcon" />
         
          <div className="text-home">
            <NavLink
              to="/"
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "white",
              })}
            >
              <p title="Home">Home</p>
            </NavLink>
          </div>
        </Fade>
        <div className="text-blog">
          <Fade bottom duration={700} delay={500}>
            <p title="Blog">Blog</p>
          </Fade>
        </div>
      </div>
    </section>
  );
}
