import React from "react";
import "./mainpart.scss";
import Mission from "../../../assets/images/mission-card-image.svg";
import Vision from "../../../assets/images/vision-card-image.svg";
import { Fade } from "react-reveal";
export default function AboutMainPart() {
  const about_card = [
    {
      image: Mission,
      heading: "Our Mission and Values",
      desc1:
        "At Vedhas AI Technologies, our mission is to revolutionize the digital landscape by delivering next-generation software solutions that empower businesses and individuals to thrive. We are dedicated to crafting high-quality, innovative application solutions that cater to the unique needs of our clients. By leveraging cutting-edge technology and industry expertise, we strive to create impactful products that drive growth, efficiency, and success in an ever-evolving digital world.",
      desc2:
        "Our values are rooted in a commitment to excellence and integrity. We build long-term partnerships with our clients based on transparency, trust, and exceptional service. Our approach is centered on understanding our clients' goals and challenges, providing tailored solutions that exceed expectations. At Vedhas, we are passionate about fostering meaningful relationships and ensuring that our clients not only stay ahead of the curve but also achieve sustained success through our dedicated support and innovative technology.",
    },
    {
      image: Vision,
      heading: "Our Vision and Journey",
      desc1:
        "At Vedhas AI Technologies, our vision is to be at the forefront of software innovation, empowering organizations and individuals to unlock their full potential. We aspire to create a future where high-quality and equitable software solutions are accessible to every business, enabling them to excel in their respective fields. By fostering a culture of collaboration, innovation, and excellence, we aim to set new standards in the software development industry, becoming the go-to partner for businesses seeking transformative technology solutions.",
      desc2:
        "Our journey is driven by a relentless pursuit of brilliance and a commitment to making a meaningful impact. We believe in the power of technology to drive progress and create opportunities, and we are dedicated to supporting our clients as they navigate their digital transformation. Through our innovative solutions and unwavering dedication, we strive to not only meet but exceed the evolving needs of our clients, ensuring they achieve their goals and thrive in a rapidly changing world.",
    },
  ];
  return (
    <section className="about_page">
      <div className="container">
        <div className="about-card-grid">
          {about_card &&
            about_card?.map((el, i) => {
              return (
                <div className="about-sub-card" title={el?.heading} key={i}>
                  <Fade bottom duration={500} delay={300}>
                    <img src={el?.image} alt="Mission" />
                  </Fade>
                  <Fade bottom duration={600} delay={400}>
                    <h2>{el?.heading}</h2>
                  </Fade>
                  <Fade bottom duration={700} delay={500}>
                    <p>{el?.desc1}</p>
                  </Fade>
                  <Fade bottom duration={800} delay={600}>
                    <p>{el?.desc2}</p>
                  </Fade>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
}
