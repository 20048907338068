import React from 'react'
import Herobanner from '../components/HomeComponent/herobanner'
import Service from '../components/HomeComponent/services'
import Finestwork from '../components/HomeComponent/finestwork'
import Technology from '../components/HomeComponent/technology'
import Project from '../components/HomeComponent/project'
export default function Home() {
    return (
        <>
            <Herobanner />
            <Service />
            <Finestwork />
            <Technology />
            <Project />
        </>
    )
}