import React from "react";
import "./BDbook.scss";
import BlogDetailsBook from "../../../assets/images/Blog-details-book.svg";
import { NavLink } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
export default function BDbook() {
  return (
    <section className="blog_detail_book">
      <div className="container">
        <div className="main-grid-blog-details">
          <div className="bd-text">
            <Fade bottom duration={600} delay={400}>
              <h2>Importance of a Strategic Approach</h2>
            </Fade>
            <Fade bottom duration={700} delay={500}>
              <p>
                A <b>strategic approach to UI/UX design</b> involves planning
                and aligning design decisions with broader business goals. It
                goes beyond creating visually appealing interfaces and focuses
                on achieving specific outcomes, such as increased user
                engagement, conversion rates, and customer satisfaction.
              </p>
            </Fade>
            <NavLink>
              <Fade bottom duration={800} delay={600}>
                <button>Learn More</button>
              </Fade>
            </NavLink>
          </div>
          <div className="bd-image">
            <Zoom delay={700}>
              <img src={BlogDetailsBook} alt="book" />
            </Zoom>
          </div>
        </div>
      </div>
    </section>
  );
}
