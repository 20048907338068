import React from "react";
import "./servicemain.scss";
import Services1 from "../../../assets/images/Services (1).svg";
import Services2 from "../../../assets/images/Services (2).svg";
import Services3 from "../../../assets/images/Services (3).svg";
import Services4 from "../../../assets/images/Services (4).svg";
import Services5 from "../../../assets/images/Service (5).svg";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";
export default function MainGrid() {
  const Service_card = [
    {
      image: Services1,
      heading: "Software & IT Outsourcing",
      description:
        "Our team of experienced developers and IT professionals are here to support your business needs. From custom software solutions to IT infrastructure management, we ensure your operations run smoothly and efficiently.",
      read_more: "Read More",
    },
    {
      image: Services2,
      heading: "Website & App Development",
      description:
        "We craft responsive, user-friendly websites and mobile apps that engage your audience and drive growth. Whether you need a new website, an app for your business, or an update to your existing digital presence, we've got you covered.",
      read_more: "Read More",
    },
    {
      image: Services3,
      heading: "Digital Marketing & SEO",
      description:
        "Our marketing experts will help your business stand out in a crowded marketplace. We offer comprehensive digital marketing services, including SEO, social media marketing, content creation, and more to ensure your brand reaches its full potential online.",
      read_more: "Read More",
    },
    {
      image: Services4,
      heading: "Innovative Digital Products",
      description:
        "At Vedhas AI Technologies, we don't just work for others—we create our own digital products too. Our innovative solutions are designed to meet the demands of the modern digital landscape, providing value and enhancing productivity for businesses around the globe.",
      read_more: "Read More",
    },
    {
      image: Services5,
      heading: "UI / UX Design",
      description:
        "Transform user experiences with our UI/UX design services. We craft intuitive interfaces and engaging designs tailored to your audience, ensuring seamless interaction and enhancing user satisfaction. Let us elevate your digital presence with captivating design solutions that leave a lasting impression.",
      read_more: "Read More",
    },
    {
      image: Services3,
      heading: "AI/ML Services",
      description:
        "Unlock the potential of artificial intelligence and machine learning with our advanced AI/ML services. We develop intelligent systems that can analyze data, automate processes, and provide actionable insights to drive your business forward.",
      read_more: "Read More",
    },
  ];
  return (
    <section className="service_page_card">
      <div className="container">
        <div className="service-main-grid">
          {Service_card &&
            Service_card?.map((el, i) => {
              return (
                <Fade bottom duration={800} delay={700} key={i}>
                  <div className="service-sub-card" title={el?.heading}>
                    <div className="description">
                      <img src={el?.image} alt="Icon1" />
                      <h2>{el?.heading}</h2>
                      <p>{el?.description}</p>
                    </div>
                    <NavLink
                      to="/mobileapp"
                      style={({ isActive }) => ({
                        color: isActive ? "#583FBB" : "#583FBB",
                        textDecoration: "none",
                      })}
                    >
                      <h3 title={el?.read_more}>
                        {el?.read_more}{" "}
                        <svg
                          width="21"
                          height="8"
                          viewBox="0 0 21 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
                            fill="url(#paint0_linear_11_161)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_11_161"
                              x1="2"
                              y1="3.49996"
                              x2="73"
                              y2="3.99997"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#8F55CA" />
                              <stop offset="1" stopOpacity="0" />
                              <stop offset="1" stopColor="#7349C2" />
                              <stop offset="1" stopColor="#7349C2" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </h3>
                    </NavLink>
                  </div>
                </Fade>
              );
            })}
        </div>
      </div>
    </section>
  );
}
