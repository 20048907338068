import React from "react";
import "./herobanner.scss";
import HerobannerImage from "../../../assets/images/icons/Asset 1 1.png";
import { Fade, Zoom } from "react-reveal";
import { Link } from "react-router-dom";
export default function Herobanner() {
  return (
    <div className="hero_banner_bg">
      <div className="hero-banner-main-grid">
        <div className="sub-grid-text">
          <Fade bottom duration={600} delay={400}>
            <h1 title="Transform Your Business with AI Solutions">
              Transform Your Business with
              <span> AI Solutions</span>
            </h1>
          </Fade>
          <Fade bottom duration={700} delay={500}>
            <p
              title="At Vedhas AI, we are passionate about creative problem-solving,
              innovative thinking, and pushing the boundaries of what's possible
              for brands. With every client, we are committed to delivering
              forward-thinking solutions that drive success in the digital age.
              We also pride ourselves on creating superior products using
              cutting-edge technology."
            >
              At Vedhas AI, we are passionate about creative problem-solving,
              innovative thinking, and pushing the boundaries of what's possible
              for brands. With every client, we are committed to delivering
              forward-thinking solutions that drive success in the digital age.
              We also pride ourselves on creating superior products using
              cutting-edge technology.
            </p>
          </Fade>
          <Fade bottom duration={800} delay={600}>
            <Link to={"/contact"}>
              <button title="Start a Conversation">
                Start a Conversation
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5829 5.26754C10.5955 5.36574 10.6274 5.46046 10.6769 5.54625C10.7263 5.63204 10.7922 5.70719 10.8708 5.76738C10.9495 5.82756 11.0392 5.87158 11.1349 5.89689C11.2307 5.92221 11.3304 5.92833 11.4285 5.91489L16.2651 5.27245L5.53362 19.2915C5.41271 19.4495 5.3595 19.649 5.38569 19.8462C5.41188 20.0433 5.51533 20.222 5.67328 20.343C5.83123 20.4639 6.03073 20.5171 6.22791 20.4909C6.42509 20.4647 6.6038 20.3612 6.7247 20.2033L17.4471 6.19613L18.0807 11.0071C18.1074 11.2034 18.2107 11.3812 18.368 11.5017C18.5254 11.6221 18.724 11.6754 18.9205 11.6499C19.1168 11.6232 19.2946 11.5199 19.415 11.3626C19.5355 11.2053 19.5888 11.0067 19.5633 10.8102L18.675 4.12311C18.6651 4.03469 18.6375 3.94918 18.5937 3.87172C18.5499 3.79427 18.4909 3.72646 18.4203 3.67238C18.3496 3.6183 18.2688 3.57906 18.1826 3.55702C18.0964 3.53499 18.0066 3.5306 17.9187 3.54414L11.2302 4.42187C11.132 4.4345 11.0373 4.46645 10.9515 4.51588C10.8657 4.56531 10.7906 4.63124 10.7304 4.70985C10.6702 4.78847 10.6262 4.87823 10.6009 4.97395C10.5755 5.06966 10.5694 5.16945 10.5829 5.26754Z"
                    fill="white"
                  />
                </svg>
              </button>
            </Link>
          </Fade>
        </div>
        <div className="sub-grid-image">
          <Zoom>
            <img
              title="Empower Your Ideas with AI Collaboration"
              src={HerobannerImage}
              alt="herobanner"
            />
          </Zoom>
        </div>
      </div>
    </div>
  );
}
