import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
export default function Footer() {
  return (
    <footer>
      <div className="footer-background">
        <div className="container">
          <div className="footer-heading">
            <Fade bottom duration={600} delay={400}>
              <h6 className="head" title="Get To Know Us!">
                Get To Know Us!
              </h6>
            </Fade>
            <Fade bottom duration={700} delay={500}>
              <p
                title="Explore who we are, our mission, and values. Learn about our
                team's expertise, commitment to innovation, and dedication to
                customer satisfaction."
              >
                Explore who we are, our mission, and values. Learn about our
                team's expertise, commitment to innovation, and dedication to
                customer satisfaction.
              </p>
            </Fade>
          </div>
          <div className="footer-main-grid">
            <div className="sub-grid-info">
              <div className="main-contact-info">
                <div className="sub-contact">
                  <Fade bottom>
                    <p title="Email: vedhasaitech@gmail.com">
                      Email: vedhasaitech@gmail.com
                    </p>
                  </Fade>
                  <Fade bottom>
                    <p title="India: +91 88668 61316">India: +91 88668 61316</p>
                  </Fade>
                  <Fade bottom>
                    <p title="Address: 224,Atlanta Shopping Mall, Beside Abhishek-3, Mota Varachha Road, Surat City, Surat- 395006, Gujarat">
                      Address: 223,Atlanta Shopping Mall, Beside Abhishek-3,
                      Mota Varachha Road, Surat City, Surat- 395006, Gujarat.
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
            <div className="sub-grid-info">
              <div className="main-contact-info2">
                <Fade bottom>
                  <div className="sub-contact">
                    <Link to={"/blog"}>
                      <p>Insights</p>
                    </Link>
                    <Link to={"/services"}>
                      <p>Our Expertise</p>
                    </Link>
                  </div>
                </Fade>
                <Fade bottom>
                  <div className="sub-contact">
                    <Link to={"/"}>
                      <p>Home</p>
                    </Link>
                    <Link to={"/product"}>
                      <p>Our Work</p>
                    </Link>
                    <Link to={"/contact"}>
                      <p>Contact Us</p>
                    </Link>
                    <Link to={"/about"}>
                      <p>Know us</p>
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <Zoom>
            <p title="&copy; Copyright 2024 - VEDHAS AI TECHNOLOGIES PRIVATE LIMITED">
              &copy; Copyright 2024 - VEDHAS AI TECHNOLOGIES PRIVATE LIMITED
            </p>
          </Zoom>
        </div>
      </div>
    </footer>
  );
}
