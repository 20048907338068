import React, { useEffect, useState } from "react";
import "./contactform.scss";
import { Zoom } from "react-reveal";
import toast from "react-hot-toast";
export default function Form() {
  const [data, setData] = useState([]);
  const [userIp, setUserIp] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        setUserIp({ rawdata: data });
      })
      .catch((error) => console.error(error));
  }, []);
  const handleOnChange = (e) => {
    setData({ ...data, [e?.target?.name]: e?.target?.value });
    setError({ ...error, [e?.target?.name]: "" });
  };
  const handleOnClick = async (e) => {
    e?.preventDefault();
    const requiredFields = [
      "First_Name",
      "Last_Name",
      "Phone_Number",
      "Email",
      "Service",
      "Budget",
      "When_Start",
      "Requirement",
      "Message",
    ];
    const newErrors = {};
    let hasError = false;

    requiredFields?.forEach((el) => {
      if (!data[el]) {
        newErrors[el] = `${el} is required`;
        hasError = true;
      }
    });

    if (hasError) {
      setError(newErrors);
      return;
    }

    const existingData = JSON?.parse(localStorage?.getItem("Data")) || [];
    const newData = [...existingData, data];
    localStorage?.setItem("Data", JSON?.stringify(newData));

    const Notification_Message = ` 
      - First Name: ${data?.First_Name}
      - Last Name: ${data?.Last_Name}
      - Phone Number: ${data?.Phone_Number}
      - Email: ${data?.Email}
      - Service: ${data?.Service}
      - Budget: ${data?.Budget}
      - When Start: ${data?.When_Start}
      - Requirement: ${data?.Requirement}
      - Message: ${data?.Message}
      - UserData: ${JSON?.stringify(userIp?.rawdata)}
    `;

    try {
      await fetch(
        "https://hooks.slack.com/services/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",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON?.stringify({ text: Notification_Message }),
        }
      );
      toast?.success(
        "Thank you for reaching out! Your message has been sent successfully."
      );
    } catch (error) {
      toast?.error(
        "Oops! Something went wrong while sending your message. Please try again later."
      );
    }

    setData({
      First_Name: "",
      Last_Name: "",
      Phone_Number: "",
      Email: "",
      Service: "",
      Budget: "",
      When_Start: "",
      Requirement: "",
      Message: "",
    });
  };
  return (
    <section className="contact_page">
      <div className="container">
        <Zoom>
          <form className="contact_form">
            <div className="row">
              <div className="col Mid">
                <input
                  className="input"
                  type="text"
                  placeholder="First Name"
                  name="First_Name"
                  required
                  onChange={handleOnChange}
                  value={data?.First_Name}
                />
              </div>
              <div className="col Mid">
                <input
                  className="input"
                  type="text"
                  placeholder="Last Name"
                  name="Last_Name"
                  required
                  onChange={handleOnChange}
                  value={data?.Last_Name}
                />
              </div>
              <div className="col Small">
                <input
                  className="input"
                  type="number"
                  placeholder="Phone Number"
                  name="Phone_Number"
                  required
                  onChange={handleOnChange}
                  value={data?.Phone_Number}
                />
              </div>
              <div className="col Small">
                <input
                  className="input"
                  type="email"
                  name="Email"
                  placeholder="Your Email"
                  required
                  onChange={handleOnChange}
                  value={data?.Email}
                />
              </div>
              <div className="col Small">
                <select
                  className="input"
                  name="Requirement"
                  value={data?.Requirement}
                  onChange={handleOnChange}
                >
                  <option value="">Requirement</option>
                  <option value="Hire Dedicated Team">
                    Hire Dedicated Team
                  </option>
                  <option value="New Project">New Project</option>
                  <option value="Existing Project">Existing Project</option>
                  <option value="Support - Maintenance">
                    Support - Maintenance
                  </option>
                </select>
              </div>
              <div className="col Small">
                <select
                  className="input"
                  name="Service"
                  value={data?.Service}
                  onChange={handleOnChange}
                >
                  <option value="">Select a Service</option>
                  <option value="Mobile Application Development">
                    Mobile Application Development
                  </option>
                  <option value="Web Development">Web Development</option>
                  <option value="Customized Open Source Product">
                    Customized Open Source Product
                  </option>
                  <option value="Consulting - Cloud and DevOps">
                    Consulting - Cloud and DevOps
                  </option>
                  <option value="UI / UX Design">UI / UX Design</option>
                  <option value="IOT / AI / ML or Wearable">
                    IOT / AI / ML or Wearable
                  </option>
                  <option value="Mobile Games Development">
                    Mobile Games Development
                  </option>
                  <option value="QA Service">QA Service</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="col Small">
                <select
                  className="input"
                  name="Budget"
                  value={data?.Budget || ""}
                  onChange={handleOnChange}
                >
                  <option value="">Budget</option>
                  <option value="$5K">$5K</option>
                  <option value="$10K+">$10K+</option>
                  <option value="$25K+">$25K+</option>
                  <option value="$50K+">$50K+</option>
                  <option value="$100K">$100K+</option>
                </select>
              </div>
              <div className="col Small">
                <select
                  className="input"
                  name="When_Start"
                  value={data?.When_Start}
                  onChange={handleOnChange}
                >
                  <option value="">How soon you want to start?</option>
                  <option value="Right now">Right now</option>
                  <option value="In few Weeks">In few Weeks</option>
                  <option value="In few Months">In few Months</option>
                  <option value="Not sure">Not sure</option>
                </select>
              </div>
              <div className="col Large">
                <textarea
                  className="input"
                  placeholder="Start Typing Your message"
                  name="Message"
                  required
                  onChange={handleOnChange}
                  value={data?.Message}
                ></textarea>
              </div>
            </div>
            <button onClick={(e) => handleOnClick(e)} className="submit_btn">
              Submit
            </button>
          </form>
        </Zoom>
      </div>
    </section>
  );
}
