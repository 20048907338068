import "./scss/global.scss";
import { RouterProvider } from "react-router-dom";
import router from "./route";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <>
      <RouterProvider router={router} />{" "}
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            border: "2px solid #583fbb",
            padding: "5px",
            fontStyle: "normal",
            backgroundColor:
              "linear-gradient(180deg, #FBF9FF 0%, rgba(235, 232, 255, 0.7) 100%)",
            letterSpacing: "0.5px",
            color: "#6C6C6C",
            fontWeight: 600,
            maxWidth: 450,
            fontSize: "12px",
            top: "84px",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
            marginBottom: "10px",
          },
        }}
        position="top-center"
        reverseOrder={true}
      />
    </>
  );
}
export default App;
