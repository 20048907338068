import React from "react";
import "./project.scss";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
export default function Project() {
  return (
    <section className="got_project_in_mind">
      <div className="container">
        <Fade bottom duration={600} delay={400}>
          <h5 className="heading" title="Got a Project in Mind?">
            Got a Project in Mind? Let’s <span>Get Started!</span>
          </h5>
        </Fade>
        <Fade bottom duration={700} delay={500}>
          <p>
            Share your innovative vision with Vedhas AI Technologies. Our
            dedicated team is ready to collaborate with you every step of the
            way, providing expertise and cutting-edge solutions to turn your
            ideas into reality. Let’s bring your project to life together.
          </p>
        </Fade>
        <Link to={"/contact"}>
          <Fade bottom duration={800} delay={600}>
            <button title="Drop a Message">Drop a Message</button>
          </Fade>
        </Link>
      </div>
    </section>
  );
}
