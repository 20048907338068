import React from "react";
import "./BDHerobanner.scss";
import BlogDetailsImage from "../../../assets/images/Rectangle blog-details-image.svg";
import { Fade, Zoom } from "react-reveal";
export default function BDHerobanner() {
  return (
    <section className="blog_details_herobanner">
      <div className="container">
        <div className="blog_detail_heading">
          <Fade bottom duration={600} delay={400}>
            <p>27 March 2024</p>
          </Fade>
          <Fade bottom duration={700} delay={500}>
            <h1>Strategic­ UI/UX­ Designing Impactful Experiences Part-1</h1>
          </Fade>
          <Zoom delay={600}>
            <img src={BlogDetailsImage} alt="Details" />
          </Zoom>
        </div>
        <div className="text_paragraph">
          <Fade bottom duration={800} delay={600}>
            <p>
              <b>User Interface (UI) Design</b> focuses on the visual aspects of
              an application or website. It involves the arrangement of buttons,
              icons, images, and other elements to create an aesthetically
              pleasing and user-friendly interface. UI design aims to enhance
              the overall look and feel of a digital product, making it visually
              appealing and easy to navigate.
            </p>
          </Fade>
          <Fade bottom duration={900} delay={700}>
            <p>
              <b>User Experience (UX) Design,</b> on the other hand, is a
              broader concept. It encompasses all aspects of the end user's
              interaction with a company, its services, and its products. UX
              design involves understanding user behavior, conducting research,
              and creating seamless, enjoyable experiences. It goes beyond the
              visual interface to consider the overall journey a user takes from
              the first interaction to task completion.
            </p>
          </Fade>
          <Fade bottom duration={1000} delay={800}>
            <p>
              <b>UI/UX Design</b> is an interdisciplinary field that combines
              elements of graphic design, psychology, and human-computer
              interaction. It plays a crucial role in shaping how users perceive
              and interact with digital products, ultimately influencing their
              satisfaction and loyalty.
            </p>
          </Fade>
        </div>
      </div>
    </section>
  );
}
