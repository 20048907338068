import React from 'react'
import ContactHead from '../components/ContactComponent/ContactHead'
import Form from '../components/ContactComponent/ContactForm'

export const Contact = () => {
  return (
    <>
        <ContactHead/>
        <Form/>
    </>
  )
}
