import React from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../common/scrollToTop";

export const DefaultLayout = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
