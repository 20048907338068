import React from "react";
import "./productmain.scss";
import Product1 from "../../../assets/images/Group product 1.svg";
import Product2 from "../../../assets/images/Group product 2.svg";
import Product3 from "../../../assets/images/Group product 3.svg";
import { Fade, Zoom } from "react-reveal";
export default function ProductMain() {
  const Product_Card = [
    {
      image: Product2,
      heading: "AssignmentGPT AI",
      Link: "AssignmentGPT AI",
      url: "https://assignmentgpt.ai",
      description:
        ",  where academic excellence meets the latest in technology! Our AI-powered platform is revolutionizing homework assistance by offering personalized support tailored to students of all levels. With features such as instant feedback, step-by-step solutions, and adaptive learning experiences, we ensure that every student receives the guidance they need to succeed. Backed by a team of experienced educators and AI specialists, our commitment is to empower students to conquer their academic challenges and reach their full potential. Whether it's math problems, science concepts, essay writing, or exam preparation, our intuitive platform and interactive tools are available 24/7 to provide comprehensive support. Join us today for a free trial and witness the future of homework help with AI Homework Helper!",
    },
    {
      image: Product3,
      heading: "AI GYM",
      Link: "AI Gym",
      url: "https://play.google.com/store/apps/details?id=com.fitever.fitness.workout.diet.planner",
      description:
        ",  your ultimate fitness companion powered by artificial intelligence! Revolutionize your workout routine with our cutting-edge platform, which offers personalized training programs, real-time feedback, and intelligent coaching to help you reach your fitness goals faster. Whether you're a beginner looking to get started on your fitness journey or a seasoned athlete striving for peak performance, AI Gym adapts to your needs and preferences, providing tailored workouts and insights based on your progress and feedback. With an extensive library of exercises, virtual trainers, and interactive challenges, staying motivated and making progress has never been easier. Join AI Gym today and unlock your full potential with the power of AI!",
    },
    {
      image: Product1,
      heading: "Botify Me",
      description:
        "Botifyme is a versatile and fully customizable chatbot designed to meet your unique needs. Whether you’re looking to create a sales bot that engages customers and drives conversions or an educational bot that provides valuable information and learning opportunities, Botifyme is the perfect solution. With the ability to save and manage knowledge efficiently, Botifyme ensures that your bot is always up-to-date and ready to deliver the right information at the right time. Tailor your bot to suit your business or educational needs and take your interactions to the next level with Botifyme.",
    },
  ];
  return (
    <section className="product_page">
      {Product_Card &&
        Product_Card?.map((el, i) => {
          return (
            <div className="main-background-color-product" key={i}>
              <div className="container">
                <div className="main-part-flex">
                  <div className="sub-image-product">
                    <Zoom>
                      <img src={el?.image} alt="Productimage1" />
                    </Zoom>
                  </div>
                  <div className="sub-text-product">
                    <Fade bottom duration={600} delay={400}>
                      <h2 title={el?.heading}>{el?.heading}</h2>
                    </Fade>
                    <Fade bottom duration={700} delay={500}>
                      <p title={el?.description}>
                        <a href={el?.url} target="_blank" rel="noreferrer">
                          {el?.Link}
                        </a>
                        {el?.description}
                      </p>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </section>
  );
}
